table {
  width: 100%;
}

.invoice-template-table tr,
.invoice-template-table td,
.invoice-template-table th {
  padding: 5px 5px;
  border: 1px solid black;
}

table {
  border-collapse: collapse;
  /* border: 1px solid black; */
}
.table-invoice-no {
  font-size: 17px;
  font-weight: 700 !important;
}
