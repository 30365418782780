:root {
  --font-family: "Inter", sans-serif;
}

code {
  font-family: var(--font-family);
}
body {
  font-family: var(--font-family);
  font-weight: 300;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
strong {
  font-weight: 500 !important;
  font-family: var(--font-family);
}
span,
a {
  font-weight: 400 !important;
  font-family: var(--font-family);
}
small,
p {
  font-weight: 300 !important;
  font-family: var(--font-family);
}
pre {
  font-size: 14px;
  font-family: var(--font-family);
}
.spinner-overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  /* cursor: pointer; Add a pointer on hover */
}
#spinner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  /* font-size: 50px; */
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.congrats {
  text-align: center;
  width: 100%;
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}
@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}
@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}
.container {
  width: 100vw;
  height: 75vh;
  background: #fff;
}
.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;
}
.confetti--animation-slow {
  animation: confetti-slow 2.25s linear 1 forwards;
}
.confetti--animation-medium {
  animation: confetti-medium 1.75s linear 1 forwards;
}
.confetti--animation-fast {
  animation: confetti-fast 1.25s linear 1 forwards;
}
/* Checkmark */
.checkmark-circle {
  width: 140px;
  height: 140px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.checkmark-circle .background {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: #00c09d;
  position: absolute;
}
.checkmark-circle .checkmark {
  border-radius: 5px;
}
.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 75px;
  width: 37.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 15px solid white;
  border-top: 15px solid white;
  border-radius: 2.5px !important;
  content: "";
  left: 25px;
  top: 75px;
  position: absolute;
}
@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
.submit-btn {
  height: 45px;
  width: 200px;
  font-size: 15px;
  background-color: #00c09d;
  border: 1px solid #00ab8c;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(87, 71, 81, 0.2);
  cursor: pointer;
  transition: all 2s ease-out;
  transition: all 0.2s ease-out;
}
.submit-btn:hover {
  background-color: #2ca893;
  transition: all 0.2s ease-out;
}

.skeleton-image {
  width: 50px;
  height: 50px;
  background-color: #ecebeb;
  border-radius: 50%;
  margin: 0 auto;
  animation: 1.5s shine linear infinite;
}

.skeleton-text {
  margin-top: 10px;
}

.skeleton-line {
  width: 100%;
  height: 10px;
  background-color: #ecebeb;
  margin-bottom: 10px;
  animation: 1.5s shine linear infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.skeleton-circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
.card.is-loading .image,
.card.is-loading h2,
.card.is-loading p {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
.card.is-loading .image {
  height: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.card.is-loading h2 {
  height: 50px;
}
.card.is-loading p {
  margin-top: 9px;
  height: 50px;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.product-collapse {
  height: max-content; /* fixed size required */
  transition: all 0.5s;
}
.product-collapse.hide {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.main-error-page {
  min-height: 600px;
  margin: 0px auto;
  width: auto;
  max-width: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error-title {
  max-width: 529px;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4b4b62;
  margin-bottom: 16px;
}

.error-subtitle {
  max-width: 568px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #4b4b62;
  margin-bottom: 24px;
}
svg {
  /* margin-bottom: 16px; */
  overflow: hidden;
  vertical-align: middle;
}

.full-torradeira {
}
.torradeira {
}

.pao-atras {
  animation: leftright 1s alternate infinite;
  transform-origin: center;
}
.pao-frente {
  animation: leftright 1s 0.3s alternate infinite;
  transform-origin: center;
}

.olho-esq {
  animation: sad 2s alternate infinite;
  transform-origin: center;
}

.olho-dir {
  animation: sad 2s alternate infinite;
  transform-origin: center;
}
.boca {
  animation: sad 2s alternate infinite;
  transform-origin: center;
}
.raios {
  -webkit-animation: flicker-4 4s linear infinite both;
  animation: flicker-4 4s linear infinite both;
}
.tomada {
  -webkit-animation: vibrate-1 3s linear infinite both;
  animation: vibrate-1 3s linear infinite both;
}
.fio-500 {
  -webkit-animation: vibrate-1 3s linear infinite both;
  animation: vibrate-1 3s linear infinite both;
}
.fio {
  -webkit-animation: vibrate-1 3s linear infinite both;
  animation: vibrate-1 3s linear infinite both;
}

@keyframes scales {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 14:58:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-4
 * ----------------------------------------
 */
@-webkit-keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}
@keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:17:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:42:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes wobble-ver-right {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(6deg);
    transform: translateY(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(-6deg);
    transform: translateY(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(3.6deg);
    transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(-2.4deg);
    transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(1.2deg);
    transform: translateY(-6px) rotate(1.2deg);
  }
}

@keyframes sad {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(10deg) rotateY(5deg);
  }
}

@keyframes leftright {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-15deg);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.page-fade-in {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  width: 100%;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  margin: 1 rem;
}
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: all 0.6s ease;
}
.progress-analysis {
  font-size: 1rem;
  height: 1.5rem;
  border: 1px solid #c7daff;
}
.progress-bar-analysis {
  background-color: #87bcf1;
  color: #474855;
}
.need-support {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.underline {
  text-decoration: underline;
}
.my-cards-height {
  min-height: 92%;
}
.cards-full-height {
  height: 100%;
}
.btn-link {
  color: #4340c2 !important;
}
.card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content-body {
  transition: opacity 0.5s ease-in-out;
}

/* Beginning of email list css */

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #6967ce;
  border-color: #6967ce;
  color: #444;
  z-index: 2;
}

.list-group-item,
.list-group-item:first-child,
.list-group-item:last-child {
  border-radius: 0px;
  padding: 12px 20px;
}

.list-group-item-heading {
  font-weight: 300;
}
.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: #3bc0c3;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
  color: #3bc0c3;
}
.m-t-40 {
  margin-top: 40px !important;
}
.panel {
  border: none;
  box-shadow: none;
}
.panel .panel-body {
  padding: 0px;
}
.panel .panel-body p {
  margin: 0px;
}
.panel .panel-body p + p {
  margin-top: 15px;
}
.panel-default > .panel-heading {
  background-color: #ffffff;
  border-color: #dddddd;
  color: #797979;
}
.panel-heading {
  border-color: #eff2f7;
  font-size: 16px;
  padding: 0;
  padding-bottom: 15px;
}
.panel-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
}
.panel-footer {
  margin: 0px -30px -30px;
  background: #eee;
  border-top: 0px;
}
.panel-group .panel .panel-heading {
  padding-bottom: 0;
  border-bottom: 0;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 0;
}
.m-t-20 {
  margin-top: 20px;
}
.mail-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-default {
  color: #333;
  padding: 6px 12px;
  background-color: #fff;
  border-color: #ccc;
}
.nopadding {
  padding: 0;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.truncate-text {
  display: inline-block;
  max-width: 100%;
}
.bold {
  font-weight: 600 !important;
  color: black;
}

.table-loader {
  width: 100%;
  border-collapse: collapse;
}

.table-loader thead {
  display: none;
}

.table-loader tbody {
  display: block;
}

.table-loader tr {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.table-loader td {
  flex: 1;
  padding: 10px;
}

.table-loader p,
.table-loader span {
  width: 100%;
  height: 20px;
  background-color: #eee;
  border-radius: 4px;
  position: relative;
}

.table-loader .bold {
  font-weight: bold;
}

.table-loader i.fa {
  margin-right: 5px;
}

.table-loader .mr-1 {
  margin-right: 5px;
}

/* Animation */
@keyframes skeleton-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.table-loader p:before,
.table-loader span:before {
  content: "";
  position: absolute;
  top: 0;
  left: -200px;
  width: 200px;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #e1e1e1 50%, transparent 100%);
  animation: skeleton-animation 1.5s infinite linear;
}

.table-loader p:after,
.table-loader span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.table-loader .col-1 p:before {
  width: 30px;
}

.table-loader .text-right span:before {
  width: 70px;
}
.clickable {
  cursor: pointer;
}
.media-meta {
  font-size: 10px;
}
.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}
.empty-mail-text {
  height: 40px;
  background: #f4f5fa;
  border-radius: 5px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
/* End of email list css */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: absolute;
  right: 50%;
  top: 50%;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: black;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 998px) {
  .order-status {
    text-align: right;
  }
  .shipment-status-warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 576px) {
  .flex-end-sm {
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 767px) {
  .notifications-drawer {
    width: 420px;
  }
  .quick-tile {
    font-size: 14px;
  }
  /* .swiper {
    margin-left: 20px !important;
    margin-right: 20px !important;
    position: static !important;
  } */
}
@media (max-width: 767px) {
  .profile-image-container {
    display: flex;
    align-items: center !important;
  }
  .offset-profile-box {
    margin-left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sponsor-details {
    display: flex;
    flex-direction: column;
  }
  .card-header {
    padding: 1rem !important;
  }
  .card-body {
    padding: 1rem !important;
  }
  .order-status-alert {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .container,
  .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
  }
  .mail-img {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 763px) {
  .notifications-drawer {
    max-height: 400px !important;
    overflow-y: scroll !important;
  }
  .chart-wrapper {
    width: 400px !important;
    overflow: scroll;
  }
  .sales-chart-wrapper {
    height: 400px;
    width: 100%;
  }
  .quick-tile {
    font-size: 12px;
  }
  .org-column-analysis {
    width: 50% !important;
  }
  .place-order-pv-count {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding-inline: 0;
    padding: 0.5rem;
  }
}

.product-cards {
  -webkit-box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
  -moz-box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
}
.product-cards:hover {
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2) !important;
}
.place-order-pv-count {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-direction-row {
  flex-direction: row;
}

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: var(--font-family);
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(../public/assets/app-assets/images/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}

.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f1f1f1;
  animation: fadeIn 0.5s ease-in-out; /* Add a fade-in animation */
}

.maintenance-content {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  animation: slideUp 0.5s ease-in-out; /* Add a slide-up animation */
}

.maintenance-heading {
  font-size: 28px;
  color: #e74c3c;
  margin-bottom: 10px;
}

.maintenance-text {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.chart-wrapper {
  height: 600px;
  width: 100%;
}
.sales-chart-wrapper {
  height: 400px;
  width: 100%;
}

.unread {
  background-color: #d7e9fb;
}

.dropdown-menu-content {
  /* display: none;
  position: absolute; */
  /* bottom: 85px; */
  background-color: #fefefe;
  border-radius: 5px;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: all 0.3s ease;
}
/* .genealogy-node:hover ~ .dropdown-menu-content,
.dropdown-menu-content:hover {
  display: block;
} */
.node-group {
  position: relative;
}
#genealogy-hover-table {
  border: 0;
  background: #f6f6f6;
}
#genealogy-hover-table td {
  padding: 4px !important;
  font-size: 10px;
  text-align: center;
}
.display-block {
  display: block;
  opacity: 1; /* Show the content with full opacity */
}

.donut-wrapper {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.max-height {
  height: 100%;
}
.group-volume-tile {
  text-align: center;
  vertical-align: middle !important;
  padding-top: 0 !important;
}
.group-volume-label {
  text-align: center;
  vertical-align: middle !important;
  padding-bottom: 0 !important;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: #f3f3f3; /* Background color for the sticky column */
  z-index: 1;
  font-weight: bold; /* Make the text in the sticky column bold */
}
.activation-pv-box {
  background-color: #f3f3f3;
}
.border-right {
  border-right: 1px solid #c3c3c3 !important;
}
.status-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.gv-tile {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  padding: 5px;
}
.current-week-gv {
  background-color: #e0eeff;
  border: 1px solid #e0eeff;
}
.circle-box {
  border-radius: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 21px;
  text-align: center;
  width: 50px !important;
}
.bg-light-info {
  --bs-bg-opacity: 1;
  background-color: rgb(211, 237, 250) !important;
  background-color: rgb(211, 237, 250) !important;
}
.bg-light-warning {
  --bs-bg-opacity: 1;
  background-color: rgb(248, 236, 220) !important;
  background-color: rgb(248, 236, 220) !important;
}
.bg-light-success {
  --bs-bg-opacity: 1;
  background-color: rgb(213, 243, 242) !important;
  background-color: rgb(213, 243, 242) !important;
}
.bg-light-danger {
  --bs-bg-opacity: 1;
  background-color: rgb(255 207 207) !important;
  background-color: rgb(255 207 207) !important;
}
.top-leaders-wrapper {
  max-height: 400px;
  overflow-y: scroll;
}
.org-column-analysis {
  width: 30%;
}
.equal-height {
  height: 90%;
}
.bg-light-1 {
  background-color: #f5f5f5;
}
.pre-limited-height {
  max-height: 100px;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-flex-end {
  justify-content: flex-end;
}
.sticky-element {
  position: sticky;
  background: #fafbfc;
  border-right: 1px solid #efe8e8;
}
.sticky-left {
  left: 0;
}
.sticky-right {
  right: 0;
}
.sticky-top {
  top: 0;
}
.document-name {
  font-size: 1.05rem;
  padding-inline: 5px;
}
.pv-count-sticky-top {
  position: sticky;
  top: 70px;
  z-index: 999;
}
.required {
  color: red;
}
.profile-image-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
}
.profile-image {
  height: 150px;
  width: 150px;
  object-fit: cover;
}
.profile-image-block {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.la-1-5x {
  font-size: 1.5em;
}
.or-filter-group {
  background-color: #f2f2f2;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px;
}
.or-filter-divider {
  align-items: center;
  display: inline-block;
  display: flex;
  height: 100%;
  margin: 0 10px;
}
.file-upload-button {
  cursor: pointer;
  padding: 10px 15px;
  margin: 5px;
  background-color: rgb(233 233 233);
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 2px solid rgb(233, 233, 233);
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-upload-preview-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
.justify-content-flex-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.navbar-gradient {
  background-image: linear-gradient(to right, #36beac, #8cc968);
  background-repeat: repeat-x;
}
.nav-item {
  color: #fff;
}
.breadcrumb-text-color {
  color: #333 !important;
}
.table .thead-light th {
  background-color: #e2e2e2;
  border-color: #e2e2e2;
  color: #4e5154;
}
.header-sticky {
  position: sticky;
  top: 0px;
  z-index: 99;
}

/* .label-container:hover .dropdown-menu-content {
  display: block;
}

.label-container .dropdown-menu-content {
  display: none;
} */
.question-mark {
  border-radius: 50%;
  background-color: #fff;
  color: hsl(0, 0%, 53%);
  font-weight: 500;
  width: 15px;
  height: 15px;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  margin: 0 0px 1px 6px;
  cursor: pointer;
}
.popover-custom {
  z-index: 1 !important;
}
.genealogy-name-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popover-container {
  position: relative;
  display: inline-block;
}

.popover-content {
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 250px;
}
.active-menu {
  background-color: rgba(0, 69, 139, 0.8);
  color: #b6c8d9;
  animation: fadeIn 0.3s ease-in-out;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
@keyframes fadeInNew {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.react-tooltip {
  background: #fff;
  border-radius: 5px;
  position: relative;
  font-size: 0.8rem;
  visibility: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 250px;
  border: 1px solid #ccc;
  z-index: 9999;
}

.react-tooltip[data-show="true"] {
  visibility: visible;
}
.react-tooltip .arrow,
.react-tooltip .arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  transition: top 0.3s ease-in, bottom 0.3s ease-in, left 0.3s ease-in, right 0.3s ease-in;
}

.react-tooltip .arrow {
  visibility: hidden;
}

.react-tooltip .arrow::before {
  visibility: hidden;
  content: "";
  transform: rotate(45deg);
}
.genealogy-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 125px;
}
.order-status-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-width-max-content {
  min-width: max-content;
}
.mail-img {
  width: 45px;
  height: 45px;
}
.text-small {
  font-size: 12px !important;
}

a.btn-google {
  color: #fff;
}
.btn-downlaod {
  padding: 10px 16px;
  margin: 5px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}
.btn-downlaod:active:focus,
.btn-downlaod:focus {
  outline: 0;
}
.btn-downlaod:focus,
.btn-downlaod:hover {
  color: #333;
  text-decoration: none;
  outline: 0;
  transition: 0.3s;
}
.btn-downlaod:active {
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-google {
  color: #fff;
  background-color: #111;
  padding: 15px 16px 5px 40px;
  position: relative;
  font-weight: 600;
}
.btn-google:focus {
  color: #fff;
  background-color: #555;
}
.btn-google:active,
.btn-google:hover {
  color: #fff;
  background-color: #555;
  transition: 0.3s;
}
.btn-google:before {
  content: "";
  background-image: url(../public/assets/app-assets/images/ico/435px-Google_Play_2022_icon.svg.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 6px;
  top: 50%;
  margin-top: -15px;
}
.btn-google:after {
  content: "GET IT ON";
  position: absolute;
  top: 5px;
  left: 40px;
  font-size: 10px;
  font-weight: 400;
}

.btn-apple {
  color: #fff;
  background-color: #111;
  padding: 15px 16px 5px 40px;
  position: relative;
  font-weight: 600;
}
.btn-apple:focus {
  color: #fff;
  background-color: #555;
}
.btn-apple:active,
.btn-apple:hover {
  color: #fff;
  background-color: #555;
}
.btn-apple:before {
  content: "";
  background-image: url(../public/assets/app-assets/images//ico/404px-Apple_logo_white.svg.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 6px;
  top: 50%;
  margin-top: -15px;
}
.btn-apple:after {
  content: "Download on the";
  position: absolute;
  top: 5px;
  left: 40px;
  font-size: 10px;
  font-weight: 400;
}

@keyframes tadaWithDelay {
  0%,
  80%,
  100% {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  90% {
    transform: scale3d(1, 1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes heartBeatWithDelay {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.1);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.custom-tada {
  animation: tadaWithDelay 3s infinite;
  animation-delay: 3s;
}
.custom-tada:hover {
  animation: none;
}
.custom-heartBeat {
  animation: heartBeatWithDelay 2s infinite;
  animation-delay: 3s;
}
.custom-heartBeat:hover {
  animation: none;
}

.download-stats {
  margin: 20px;
}
.text-extra-small {
  font-size: 8px !important;
}

.loader {
  border: 2px solid #fff;
  width: 48px;
  height: 48px;
  background: linear-gradient(to right, #36beac, #8cc968);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 24px solid;
  border-color: transparent #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bg-good {
  background: linear-gradient(to right, #36beac, #8cc968) !important;
}
.bg-bad {
  background: linear-gradient(to right, #ff5f6d, #ffc371) !important;
}

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto 0.6em;
  border: none !important;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}

.video-card {
  position: relative;
  border: 1px solid #e9e7e7;
  border-radius: 10px;
  width: 20rem;
  height: 100%;
  overflow: hidden;
}
.video-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}
.video-tile-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(134 134 134 / 47%);
  pointer-events: none;
}
.video-play-icon {
  font-size: 40px;
  color: rgba(255, 255, 255, 0.9);
  /* text-shadow: rgba(0, 0, 0, 0.8) 0px 2px; */
}
.video-lock-icon {
  font-size: 4rem;
}
.language-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 20px 0px;
}

/* Enterpcmretailerdetails.css */

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
}

.order-details-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0;
}

.customer-input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  gap: 10px;
}

.input-wrapper-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  gap: 10px;
}

.input-wrapper-3 {
  width: 100%;
  display: flex;
  gap: 20px;
}
/* Enterpcmretailerdetails.css */

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
}
.order-details-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}
.subtitle {
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0;
}
.customer-input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  gap: 10px;
}
.input-wrapper-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  gap: 10px;
}
.input-wrapper-3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.phone-input-container {
  width: 50%;
  position: relative;
  height: 100%;
  /* display: flex; */
}
.phone-input-container > input {
  width: 100%;
}
.input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 60%;
}
.error-text {
  color: #e74c3c;
  font-size: 12px;
  /* position: absolute;
  top: 100%; 
  left: 0;  */
  margin-top: 5px;
}
.remove-button {
  background-color: #e74c3c;
  border: none;
  color: white;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}
.button-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.add-button {
  padding: 10px;
  background-color: #00c09d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.submit-button {
  padding: 10px;
  background-color: #6967ce;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .customer-input-container {
    flex-direction: column;
  }
  .phone-input-container {
    margin-bottom: 10px;
  }
  .input {
    width: 140px;
  }
  .input-wrapper-2 {
    display: flex;
  }
  .remove-button {
    width: 100%;
    padding: 10px;
  }
  .button-container {
    flex-direction: column;
  }
  .add-button,
  .submit-button {
    width: 100%;
    padding: 12px;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 20px;
  }
  .subtitle {
    font-size: 18px;
  }
  .input {
    padding: 8px;
  }
  .remove-button,
  .add-button,
  .submit-button {
    font-size: 14px;
  }
  .phone-input-container {
    position: relative; /* Ensure proper stacking context for .error-text */
  }
  .error-text {
    font-size: 10px; /* Slightly smaller font for mobile */
    width: auto; /* Allow width to adjust naturally */
    max-width: 100%; /* Prevent it from exceeding container width */
  }
}
.pet-page-buttons {
  margin-bottom: 10px;
}
/* styles.css */
.otp-input {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.otp-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
#treeContainer {
  width: 100%;
  min-height: 600px; /* or 100vh, but watch mobile viewport issues */
  overflow: hidden;
  touch-action: "none";
}
.feedback-button {
  position: fixed;
  top: 70%;
  z-index: 5;
  right: -35px;
  rotate: -90deg;
}
.feedback-rank-btn {
  padding: 1rem 0.7rem !important;
}
.pet-node-details-wrapper {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 5px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  max-width: 90vw;
  pointer-events: auto;
}
.pet-pagination-text {
  margin-bottom: 5px;
  font-size: 10px;
  text-align: right;
  font-weight: 500;
}
/* PaymentSuccess.css */
.payment-success-page-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px;
}

.payment-success-card {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.payment-success-icon {
  width: 60px;
  height: 60px;
  background-color: #28a745;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin: 0 auto 20px;
}

.payment-success-heading {
  color: #333;
  margin-bottom: 15px;
}

.payment-success-text {
  color: #666;
  margin-bottom: 20px;
}

.payment-order-details {
  text-align: left;
  margin: 20px 0;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 5px;
}

.payment-detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #555;
}

.payment-detail-item span:first-child {
  font-weight: 500;
}

.payment-confirmation-text {
  font-size: 14px;
  color: #777;
}

.payment-continue-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.payment-continue-button:hover {
  background-color: #0056b3;
}
/* end payment success */
/* start payment failure */
/* PaymentFailure.css */
.vv-payment-failure-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px;
}

.vv-failure-card {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.vv-failure-icon {
  width: 60px;
  height: 60px;
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin: 0 auto 20px;
}

.vv-failure-heading {
  color: #333;
  margin-bottom: 15px;
}

.vv-failure-text {
  color: #666;
  margin-bottom: 20px;
}

.vv-payment-failure-details {
  text-align: left;
  margin: 20px 0;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 5px;
}

.vv-failure-detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #555;
}

.vv-failure-detail-item span:first-child {
  font-weight: 500;
}

.vv-failure-help-text {
  font-size: 14px;
  color: #777;
}

.vv-failure-button-group {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.vv-failure-try-again-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.vv-failure-try-again-button:hover {
  background-color: #b02a37;
}

.vv-failure-return-home-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.vv-failure-return-home-button:hover {
  background-color: #5a6268;
}
/* end payment failure */